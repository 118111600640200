<template>
  <div class="flex h-full flex-grow min-h-full relative">
    <div
      class="bg-brandDarkBlue-default h-full min-h-full top-0 w-96 px-8 py-8 text-white flex-shrink-0"
    >
      <h2 class="text-3xl font-semibold mb-6">New initiative</h2>
      <p class="opacity-75">
        An initiative is a high-level strategic activity with a start date, end date, and a
        collection of tasks that need to be completed in order to deliver it. It could be a project,
        a campaign, an event, or whatever makes sense for your business.
      </p>
      <hr class="opacity-50 my-6" />
      <p class="opacity-75 mb-4">Some examples:</p>
      <ul class="list-disc ml-4 opacity-75">
        <li>Secure initial funding</li>
        <li>Social media campaign</li>
        <li>Acquire the first 1000 users</li>
        <li>Plan launch event</li>
      </ul>
    </div>
    <div class="h-full w-full overflow-auto">
      <div v-if="showInitiativeTemplates" class="p-10">
        <div class="flex gap-6 mb-8">
          <gm-button
            label=""
            icon-only
            icon="back"
            secondary
            size="small"
            title="Back to form"
            @click="showInitiativeTemplates = false"
          />
          <div>
            <h3 class="mb-1">Initiative templates</h3>
            <p class="text-base text-gray-500 mb-6">
              Click to copy a template to your initiative. You can make changes to it before saving.
            </p>
          </div>
        </div>

        <InitiativeTemplates allow-copy @emittedToModal="selectInitiativeTemplate($event)" />
      </div>
      <div v-else class="p-10 w-full flex flex-col gap-4">
        <gm-action-panel
          title="Initiative templates"
          content="Speed up your workflow with a curated initiative template."
          class="mb-3"
        >
          <template #actions>
            <gm-button
              label="Browse templates"
              icon="chevron-right"
              @click="loadInitiativeTemplates"
            />
          </template>
        </gm-action-panel>
        <gm-card overflow>
          <div class="p-6 flex flex-col gap-3">
            <div class="flex justify-between items-center mb-3">
              <h4 class="mb-0">Details</h4>
              <gm-button
                label="Clear this section"
                icon="trash"
                icon-only
                size="small"
                secondary
                :disabled="buttonDisabled"
                @click="
                  title = '';
                  description = '';
                  startDate = '';
                  endDate = '';
                "
              />
            </div>

            <gm-input
              v-model="title"
              type="text"
              label="Title"
              placeholder="e.g. Secure initial funding"
              required
            />
            <gm-input
              v-model="description"
              type="textarea"
              label="Description"
              placeholder="e.g. Raise £150,000 in SEIS funding."
            />
            <div class="flex gap-4 w-full">
              <gm-input
                v-model="startDate"
                type="date"
                label="Start date"
                class="flex-1 max-w-1/2"
                placeholder="Select date"
                required
              />
              <gm-input
                v-model="endDate"
                type="date"
                label="End date"
                class="flex-1 max-w-1/2"
                placeholder="Select date"
                required
              />
            </div>
          </div>
        </gm-card>
        <gm-card overflow>
          <div class="p-6 flex flex-col gap-3">
            <div class="flex justify-between items-center mb-3">
              <h4 class="mb-0">Tasks</h4>
              <div class="flex gap-1">
                <gm-button
                  v-if="deletedTasks.length > 0"
                  :label="`Restore deleted (${deletedTasks.length})`"
                  icon="undo"
                  icon-only
                  size="small"
                  secondary
                  :disabled="tasks.length === 0"
                  @click="restoreDeletedTasks"
                />
                <gm-button
                  label="Clear all tasks"
                  icon="trash"
                  icon-only
                  size="small"
                  secondary
                  :disabled="tasks.length === 0"
                  @click="tasks = []"
                />
              </div>
            </div>

            <TaskList v-model:tasks="tasks" />
          </div>
        </gm-card>
        <div class="flex-grow-0 mt-4">
          <gm-button
            label="Create plan"
            size="large"
            primary
            :disabled="buttonDisabled"
            :loading="formSubmitting"
            @click="submitForm"
            :success="buttonSuccess"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, defineEmits } from "vue";
import useInitiativesStore from "@/store/initiatives";
import useUserStore from "@/store/user";
import InitiativeTemplates from "@/components/initiatives/InitiativeTemplates";
import TaskList from "@/components/tasks/TaskList";
import { useNotify } from "@/plugins/useNotify";
const $notify = useNotify();

// Initialize stores
const initiativesStore = useInitiativesStore();
const userStore = useUserStore();

const showInitiativeTemplates = ref(false);
const emit = defineEmits(["emittedToModal"]);

// Form fields
const title = ref("");
const description = ref("");
const startDate = ref("");
const endDate = ref("");
//const tasks = ref([]);
const tasks = ref([]);
// Return a list of deleted tasks
const deletedTasks = computed(() => {
  return tasks.value.filter((t) => t.status === "Deleted");
});

// Check if all required fields are filled to enable the submit button
const buttonDisabled = computed(() => {
  return !title.value || !startDate.value || !endDate.value;
});

// State to track if form is being submitted
const formSubmitting = ref(false);
// State to track if form submission was successful
const buttonSuccess = ref(false);
// State to store any form submission errors
const formError = ref("");

function restoreDeletedTasks() {
  // Find all tasks with a status of "Deleted" and set their status to "Not Started"
  tasks.value.forEach((t) => {
    if (t.status === "Deleted") {
      t.status = "Not Started";
    }
  });
}

function loadInitiativeTemplates() {
  showInitiativeTemplates.value = true;
}

function selectInitiativeTemplate(template) {
  /**
   * Calculate start and end dates based on the template's duration
   * - If startDate is empty, use today's date
   * - If endDate is empty, calculate it based on the template's duration
   * - Get the template's duration from the timeEstimateDays property
   * - The template's duration is in days
   */
  if (!startDate.value) {
    startDate.value = new Date().toISOString().substr(0, 10);
  }

  if (!endDate.value) {
    const endDateObj = new Date(startDate.value);
    endDateObj.setDate(endDateObj.getDate() + template.timeEstimateDays);
    endDate.value = endDateObj.toISOString().substr(0, 10);
  }

  title.value = template.title;
  description.value = template.description;
  showInitiativeTemplates.value = false;
  tasks.value = template.tasks;
}

function clearInitiativeform() {
  title.value = "";
  description.value = "";
  startDate.value = "";
  endDate.value = "";
  tasks.value = [];
}

async function submitForm() {
  formSubmitting.value = true;

  try {
    // Send form data to the server
    await initiativesStore.createInitiative({
      title: title.value,
      description: description.value,
      startDate: startDate.value,
      endDate: endDate.value,
      userId: userStore._id,
      companyId: userStore.companyId,
      tasks: tasks.value,
    });

    // If successful, show success state on the button for 3 seconds
    buttonSuccess.value = true;
    $notify(`Initiative "${title.value}" was successfully created.`, "success");

    setTimeout(() => {
      buttonSuccess.value = false;
    }, 1000);

    emit("emittedToModal", "closed");

    // Clear form fields
    clearInitiativeform();

    // Clear any previous errors
    formError.value = "";
  } catch (err) {
    // Handle any errors during form submission
    formError.value = err.message || "An error occurred while submitting the form.";
  } finally {
    formSubmitting.value = false;
  }
}

onMounted(() => {
  // eventBus.showNotification({ message: "Your message here!", type: "success" });
});
</script>
@/store/initiatives
