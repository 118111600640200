<template>
  <div>
    <gm-grid>
      <gm-card
        v-for="InitiativeTemplate in initiativesStore.initiativeTemplates"
        :key="InitiativeTemplate"
        :clickable="allowCopy"
        @click.prevent="selectPlan(InitiativeTemplate)"
      >
        <div class="p-6">
          <h4 class="text-lg text-brandDarkBlue-500">
            {{ InitiativeTemplate.title }}
          </h4>
          <p class="text-gray-600 text-sm">
            {{ InitiativeTemplate.description }}
          </p>
        </div>
      </gm-card>
    </gm-grid>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted } from "vue";
import useInitiativesStore from "@/store/initiatives";
const initiativesStore = useInitiativesStore();
const emit = defineEmits(["emittedToModal"]);

const props = defineProps({
  allowCopy: {
    type: Boolean,
    default: false,
  },
});

const selectPlan = (plan) => {
  if (props.allowCopy) {
    emit("emittedToModal", plan);
  }
};

onMounted(async () => {
  await initiativesStore.fetchInitiativeTemplates();
});
</script>
@/store/initiatives
