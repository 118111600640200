<template>
  <div class="rounded-md bg-white w-full border border-gray-200">
    <!-- Header section with navigation and date range -->
    <div class="px-6 py-3 border-b border-gray-200 bg-gray-50 flex justify-between w-full">
      <!-- Move backward button -->
      <div class="w-24 flex items-center">
        <GmToggleIcon @click="moveBackward" direction="left" :disabled="isAtStart" />
      </div>

      <!-- Display the current date range -->
      <div>
        <div
          class="flex text-sm font-semibold text-gray-700 border rounded border-gray-200 hover:border-gray-300 hover:bg-gray-100 cursor-pointer"
        >
          <div class="text-gray-400 font-normal flex items-center pl-2">Time period:</div>
          <div class="px-3 py-1.5">{{ dateRange }}</div>
        </div>
      </div>

      <!-- Move forward button -->
      <div class="flex items-center justify-end w-24">
        <GmToggleIcon @click="moveForward" direction="right" :disabled="isAtEnd" />
      </div>
    </div>

    <!-- Main content section -->
    <div class="flex card-body relative overflow-x-auto hide-scrollbar" ref="cardBody">
      <div class="relative py-6" style="width: 200%">
        <!-- Vertical line indicating current date -->
        <div
          v-if="linePositionPercentage >= 0 && linePositionPercentage <= 200"
          class="absolute top-0 bottom-0 bg-brand-500"
          :style="`left: ${linePositionPercentage}%; width: 1px;`"
        ></div>

        <!-- Display months -->
        <div class="w-full flex justify-between pb-6" style="width: 200%">
          <div
            v-for="(month, index) in currentMonths"
            :key="month"
            class="text-xs font-semibold text-black uppercase text-center"
            :class="getMonthClass(index)"
            style="width: 16.66666667%"
          >
            {{ month }}
          </div>
        </div>

        <!-- Display tasks -->
        <div class="flex flex-col gap-2 relative pb-6">
          <div
            v-for="task in tasks"
            :key="task.title"
            :ref="(el) => (taskBars[task.title] = el)"
            class="w-40 bg-brandDarkBlue-500 text-white text-xs px-3 py-2 relative whitespace-nowrap h-8"
            :style="{
              marginLeft: `${getPercentageFromStart(task.startDate)}%`,
              width: `${
                getPercentageFromStart(task.endDate) - getPercentageFromStart(task.startDate)
              }%`,
              top: 'calc(50% - 1em)',
              borderRadius: getBorderRadius(task.startDate, task.endDate),
            }"
          >
            <!-- Display task name -->
            <span
              :ref="(el) => (taskLabels[task.title] = el)"
              class="task-label absolute top-0 left-0 px-3 h-8 flex items-center"
            >
              {{ task.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import GmToggleIcon from "./gm-components/GmToggleIcon.vue";
import { computed, defineProps, ref, onMounted, onUnmounted } from "vue";

// Define the props for the component
const props = defineProps({
  startDate: {
    type: Object,
    default: () => ({
      month: new Date().toLocaleString("default", { month: "long" }),
      year: new Date().getFullYear(),
    }),
  },
  tasks: {
    type: Array,
  },
});

// Static list of month names
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Refs to keep track of each task's bar and label elements
const taskBars = ref({});
const taskLabels = ref({});

// Convert the month and year from the startDate prop to a Date object
const getStartDate = () => {
  const monthIndex = monthNames
    .map((m) => m.toLowerCase())
    .indexOf(props.startDate.month.toLowerCase());
  return new Date(props.startDate.year, monthIndex, 1);
};

// Calculate the percentage distance a given date is from the start date
const getPercentageFromStart = (date) => {
  const startDate = new Date(referenceDate.value);
  const endDate = new Date(startDate);
  endDate.setFullYear(endDate.getFullYear() + 1);

  const clampedDate = new Date(Math.max(startDate, Math.min(new Date(date), endDate)));
  const daysFromStart = Math.ceil((clampedDate - startDate) / (1000 * 60 * 60 * 24));

  const totalDaysInDisplayedMonths = Array.from({ length: 12 }).reduce((total, _, idx) => {
    const month = new Date(startDate);
    month.setMonth(startDate.getMonth() + idx);
    return total + new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
  }, 0);

  return (daysFromStart / totalDaysInDisplayedMonths) * 100 * 2;
};

const getBorderRadius = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const displayStart = new Date(referenceDate.value);
  const displayEnd = new Date(displayStart);
  displayEnd.setFullYear(displayEnd.getFullYear() + 1);

  const leftRounded = start >= displayStart ? "0.5rem" : "0";
  const rightRounded = end <= displayEnd ? "0.5rem" : "0";

  return `${leftRounded} ${rightRounded} ${rightRounded} ${leftRounded}`;
};

// State management for the component
const referenceDate = ref(getStartDate()); // The date at the start of the timeline
const currentMonths = ref([]); // Months displayed on the timeline
const cardBody = ref(null); // Ref to the main scrolling container
const isAtStart = ref(true); // Is the timeline scrolled to the start?
const isAtEnd = ref(false); // Is the timeline scrolled to the end?

// Handle the scroll event to adjust task labels and determine if at start or end of the timeline
const handleScroll = () => {
  if (cardBody.value) {
    const scrollLeft = cardBody.value.scrollLeft;
    const viewportWidth = cardBody.value.offsetWidth;

    for (const task of props.tasks) {
      const taskBar = taskBars.value[task.title];
      const taskLabel = taskLabels.value[task.title];

      const barStart = taskBar.offsetLeft;
      const barEnd = barStart + taskBar.offsetWidth;
      const labelWidth = taskLabel.offsetWidth;

      if (scrollLeft + labelWidth < barStart) {
        taskLabel.style.left = "0px";
      } else if (scrollLeft + viewportWidth < barEnd) {
        taskLabel.style.left =
          Math.min(
            scrollLeft + viewportWidth - barStart - labelWidth,
            barEnd - barStart - labelWidth
          ) + "px";
      } else {
        taskLabel.style.left =
          Math.max(0, Math.min(scrollLeft - barStart, barEnd - barStart - labelWidth)) + "px";
      }
    }

    isAtStart.value = scrollLeft === 0;
    isAtEnd.value = scrollLeft >= cardBody.value.scrollWidth - cardBody.value.offsetWidth;
  }
};

// Move the timeline forward
const moveForward = () => {
  if (cardBody.value) {
    cardBody.value.scrollBy({
      left: cardBody.value.offsetWidth,
      behavior: "smooth",
    });
  }
};

// Move the timeline backward
const moveBackward = () => {
  if (cardBody.value) {
    cardBody.value.scrollBy({
      left: -cardBody.value.offsetWidth,
      behavior: "smooth",
    });
  }
};

// Update the displayed months based on the current reference date
const updateMonths = () => {
  currentMonths.value = Array.from({ length: 12 }, (_, i) => {
    const newDate = new Date(referenceDate.value);
    newDate.setMonth(referenceDate.value.getMonth() + i);
    return monthNames[newDate.getMonth()];
  });
};

// Determine styling for a month label based on its relation to the current date
const getMonthClass = (index) => {
  const now = new Date();
  const displayedDate = new Date(referenceDate.value);
  displayedDate.setMonth(referenceDate.value.getMonth() + index);

  if (
    now.getMonth() === displayedDate.getMonth() &&
    now.getFullYear() === displayedDate.getFullYear()
  ) {
    return "text-brand-500";
  } else if (displayedDate < now) {
    return "opacity-25";
  }
  return "";
};

// Compute the full date range for the title
const dateRange = computed(() => {
  const startMonth = currentMonths.value[0];
  const startYear = new Date(referenceDate.value).getFullYear();

  const endMonthDate = new Date(referenceDate.value);
  endMonthDate.setMonth(endMonthDate.getMonth() + 11);
  const endMonth = monthNames[endMonthDate.getMonth()];
  const endYear = endMonthDate.getFullYear();

  return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
});

// Calculate the percentage position of the current date line
const linePositionPercentage = computed(() => {
  const startDate = new Date(referenceDate.value);
  const today = new Date();

  const daysBeforeToday = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));
  const totalDaysInDisplayedMonths = Array.from({ length: 12 }).reduce((total, _, idx) => {
    const month = new Date(startDate);
    month.setMonth(startDate.getMonth() + idx);
    return total + new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
  }, 0);

  return (daysBeforeToday / totalDaysInDisplayedMonths) * 100 * 2;
});

// Determine the starting offset of the timeline to show the current month
const getCurrentMonthOffset = () => {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return getPercentageFromStart(firstDayOfCurrentMonth);
};

// Add and remove event listeners upon mount and unmount
onMounted(() => {
  if (cardBody.value) {
    cardBody.value.addEventListener("scroll", handleScroll);
    const offsetPercentage = getCurrentMonthOffset();
    cardBody.value.scrollLeft = (offsetPercentage / 200) * cardBody.value.scrollWidth;
  }
  updateMonths();
});

onUnmounted(() => {
  if (cardBody.value) {
    cardBody.value.removeEventListener("scroll", handleScroll);
  }
});
</script>

<style lang="scss" scoped>
// Styles to hide scrollbars
.hide-scrollbar {
  // For Chrome, Safari, and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // For IE and Edge
  -ms-overflow-style: none;
}
</style>
