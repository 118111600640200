<template>
  <div>
    <div v-if="haveTask">
      <div class="flex justify-between items-center w-full">
        <gm-input
          v-model="newTitle"
          type="text"
          class="text-xl font-semibold flex-grow b w-full flex-1 flex mb-3"
          extra-classes="text-xl font-semibold"
          placeholder="Enter a title"
          @keyup.enter="updateTask"
          camouflaged
          auto-expand
        />
      </div>
      <div class="my-3 flex w-full">
        <textarea
          class="mb-0 flex-grow flex-1 border rounded border-gray-200 w-full hover:border-gray-200 p-2"
          v-model="newDescription"
          placeholder="Enter a description"
        >
        </textarea>
      </div>
      <div class="flex gap-1 pt-3">
        <gm-button primary label="Save changes" :disabled="!haveChanges" @click="updateTask" />
        <gm-button secondary label="Cancel" @click="cancelChanges" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref, onMounted } from "vue";

const props = defineProps({
  task: {
    type: Object,
    default: null,
  },
});

const newTitle = ref("");
const newDescription = ref("");
const haveTask = computed(() => props.task !== null);

const haveChanges = computed(() => {
  if (haveTask.value) {
    return newTitle.value !== props.task.title || newDescription.value !== props.task.description;
  }
  return false;
});

const emit = defineEmits(["updateTask", "closeModal"]);

function updateTask() {
  emit("updateTask", {
    _id: props.task._id,
    title: newTitle.value,
    description: newDescription.value,
  });
}

function cancelChanges() {
  emit("closeModal");
}

onMounted(() => {
  if (haveTask.value) {
    newTitle.value = props.task.title;
    newDescription.value = props.task.description;
  }
});
</script>

<style></style>
