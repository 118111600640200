<template>
  <div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead>
        <tr class="flex w-full items-center">
          <th class="flex-0 text-sm"></th>
          <th
            scope="col"
            class="py-3 pl-2 pr-6 flex-1 text-left text-xs font-semibold text-gray-600"
          ></th>
          <th
            scope="col"
            class="py-3 px-3 w-36 text-center flex-grow-0 text-xs font-normal text-gray-600"
          >
            Status
          </th>
          <th
            scope="col"
            class="py-3.5 px-3 w-28 flex-grow-0 text-center text-xs font-normal text-gray-600"
          >
            Due date
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr
          v-for="task in filteredTasks"
          :key="task._id"
          :class="[' flex task-item', { 'hover:bg-gray-50 ': editingTask !== task._id }]"
        >
          <td class="flex-0 text-sm flex items-center px-1">
            <div
              :class="[
                taskCheckBoxClass(task),
                'rounded-full w-5 h-5 flex items-center justify-center cursor-pointer',
              ]"
              @click.stop="toggleTaskComplete(task)"
            >
              <CheckIcon class="w-3 h-3" />
            </div>
          </td>
          <td
            class="text-sm font-medium py-3 pl-2 pr-6 flex-1 relative items-center flex cursor-pointer"
            @click.stop="editingTask === task._id ? null : viewTaskDetails(task)"
          >
            <div v-if="editingTask === task._id" class="w-full">
              <input
                type="text"
                v-model="editingTaskTitle"
                :id="task._id"
                class="border w-full text-sm py-0.5 px-0 font-medium border-transparent"
                style="margin-left: -1px; margin="
                @keyup.enter="updateEditingTask"
                @keyup.esc="editingTask = null"
                placeholder="Enter task title"
              />
            </div>
            <div v-else>
              <a
                href="#"
                class="hover:text-brand-500 hover:underline"
                title="View task details"
                @click.stop="viewTaskDetails(task)"
                v-tooltip="{ text: task.description, size: 'large' }"
                >{{ task.title }}</a
              >
              <div class="task-item-toolbar absolute right-0 top-0 flex items-center gap-1 h-full">
                <gm-toolbar-button type="edit" label="Rename" @click.stop="editTask(task)" />
                <gm-toolbar-button type="delete" label="Delete" @click.stop="deleteTask(task)" />
              </div>
            </div>
          </td>
          <td v-if="editingTask === task._id" colspan="3" class="flex items-center">
            <div class="flex gap-2 h-full items-center">
              <gm-button
                primary
                size="small"
                label="Save"
                @click.stop="updateEditingTask"
                class="w-auto"
              />
              <gm-button
                secondary
                size="small"
                label="Cancel"
                @click.stop="editingTask = null"
                class="w-auto"
              />
            </div>
          </td>
          <td v-if="editingTask !== task._id" class="py-3 px-3 w-36">
            <StatusPicker
              :status="getTaskStatus(task)"
              :open="openStatusMenuId === task._id"
              @toggle="toggleTaskStatus(task)"
              @change="changeTaskStatus($event, task)"
            />
          </td>
          <td
            v-if="editingTask !== task._id"
            class="justify-center w-28 items-center text-center py-3 px-3 text-xs text-gray-400 leading-6 hover:text-gray-900 hover:font-medium"
          >
            <gm-input
              type="date"
              :modelValue="task.dueDate"
              @update:modelValue="(newDate) => updateTaskDueDate(task, newDate)"
              @clear="clearDueDate(task)"
              placeholder="Not set"
              in-table
              date-format="M j, Y"
            />
          </td>
        </tr>
        <tr v-if="addingNewTask" class="flex items-center w-full">
          <td class="text-sm font-medium h-11 pl-0 pr-2 border-b flex-grow flex items-center">
            <input
              type="text"
              v-model="newTaskTitle"
              ref="newTaskTitleField"
              class="border rounded w-full text-sm py-1 px-2 font-medium border-transparent"
              @keyup.enter="updateNewTask"
              @keyup.esc="toggleAddNewTask"
              placeholder="Enter task title"
            />
          </td>
          <td colspan="3" class="border-b h-11 flex items-center">
            <div class="flex gap-2">
              <gm-button
                primary
                size="small"
                label="Add task"
                @click="updateNewTask"
                class="w-auto"
              />
              <gm-button
                secondary
                size="small"
                label="Cancel"
                @click="toggleAddNewTask"
                class="w-auto"
              />
            </div>
          </td>
        </tr>
        <tr v-if="!addingNewTask">
          <td
            colspan="4"
            class="text-sm text-gray-500 h-11 px-2 border-b border-gray-200 hover:bg-gray-50 hover:text-gray-900 hover:font-medium cursor-pointer"
            @click="toggleAddNewTask"
          >
            <div class="flex items-center gap-2 font-medium">
              <PlusSmallIcon class="w-5 h-5" /> New Task
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <Modal v-model="taskDetailOpen" @closed="handleTaskDetailClosed">
      <TaskDetail
        :task="selectedTask"
        @update-task="updateTask"
        @close-modal="taskDetailOpen = false"
      />
    </Modal>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref, onMounted, nextTick, onUnmounted } from "vue";
import { CheckIcon, PlusSmallIcon } from "@heroicons/vue/24/outline";
import Modal from "@/components/Modal.vue";
import TaskDetail from "@/components/tasks/TaskDetail.vue";
import StatusPicker from "@/components/StatusPicker.vue";

const props = defineProps({
  tasks: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["update:tasks"]);

// Filter out deleted tasks
const filteredTasks = computed(() => {
  return props.tasks.filter((task) => task.status !== "Deleted");
});

// Task detail
const taskDetailOpen = ref(false); // open or close the modal
const selectedTask = ref(null); // the task to show in the modal

// Load the task and open the modal
function viewTaskDetails(task) {
  closeStatusMenu();
  selectedTask.value = task;
  taskDetailOpen.value = true;
}

// Close the modal when the task detail is closed
function handleTaskDetailClosed() {
  taskDetailOpen.value = false;
}

// Handle changes to the modal
function updateTask(task) {
  const updatedTasks = props.tasks.map((t) => {
    if (t._id === task._id) {
      return task;
    }

    return t;
  });

  handleTaskDetailClosed();
  emitUpdateTasks(updatedTasks);
}

// Checkboxes
function taskCheckBoxClass(task) {
  if (task.status === "Completed") {
    return "bg-green-400 text-white border border-green-400 hover:bg-green-500 hover:border-green-500";
  }

  return "border border-gray-300 text-gray-300 hover:border-gray-400 hover:text-gray-400";
}

function toggleTaskComplete(task) {
  const updatedTasks = props.tasks.map((t) => {
    if (t._id === task._id) {
      return {
        ...t,
        status: t.status === "Completed" ? "Not Started" : "Completed",
      };
    }

    return t;
  });

  closeStatusMenu();
  emitUpdateTasks(updatedTasks);
}

// Edit task status
const statuses = ["Not Started", "In Progress", "Completed", "Blocked"];
const openStatusMenuId = ref(null);

function toggleTaskStatus(task) {
  if (openStatusMenuId.value === task._id) {
    openStatusMenuId.value = null;
  } else {
    openStatusMenuId.value = task._id;
  }
}

function changeTaskStatus(status, task) {
  const updatedTasks = props.tasks.map((t) => {
    if (t._id === task._id) {
      return {
        ...t,
        status,
      };
    }

    return t;
  });

  emitUpdateTasks(updatedTasks);

  closeStatusMenu();
}

function closeStatusMenu() {
  openStatusMenuId.value = null;
}

function getTaskStatus(task) {
  if (task.status) {
    return task.status;
  }

  return "Not started";
}

function getTaskStatusClass(status) {
  switch (status) {
    case "Not Started":
      return "bg-gray-100 text-gray-400 px-3 w-full";
    case "In Progress":
      return "bg-yellow-100 text-yellow-600 px-3 w-full";
    case "Completed":
      return "bg-green-100 text-green-600 px-3 w-full";
    case "Blocked":
      return "bg-red-100 text-red-600 px-3 w-full";
    default:
      return "bg-gray-100 text-gray-400 px-3 w-full";
  }
}

// Edit tasks
const editingTask = ref(null);
const editingTaskTitle = ref(null);

function editTask(task) {
  editingTask.value = task._id;
  editingTaskTitle.value = task.title;
  // Focus on the input field, whose ref is the task ID
  nextTick(() => {
    const inputElement = document.getElementById(task._id);
    if (inputElement) {
      inputElement.focus();
    }
  });
}

function updateEditingTask() {
  // update the tasks list
  const updatedTasks = props.tasks.map((task) => {
    if (task._id === editingTask.value) {
      return {
        ...task,
        title: editingTaskTitle.value,
      };
    }

    return task;
  });

  editingTask.value = null;

  emitUpdateTasks(updatedTasks);
}

// Update the task due date
function updateTaskDueDate(task, newDate) {
  const updatedTasks = props.tasks.map((t) => {
    if (t._id === task._id) {
      return { ...t, dueDate: newDate };
    }
    return t;
  });
  emitUpdateTasks(updatedTasks);
}

function clearDueDate(task) {
  const updatedTasks = props.tasks.map((t) => {
    if (t._id === task._id) {
      return { ...t, dueDate: "" };
    }
    return t;
  });
  emitUpdateTasks(updatedTasks);
}

// Delete a task. This imvolves updating its status to "Deleted"
function deleteTask(task) {
  const updatedTasks = props.tasks.map((t) => {
    if (t._id === task._id) {
      return {
        ...t,
        status: "Deleted",
      };
    }

    return t;
  });

  emitUpdateTasks(updatedTasks);
}

// Add a new task
const addingNewTask = ref(false);
const newTaskTitleField = ref(null);
const newTaskTitle = ref(null);
const newTask = ref({
  title: "",
  status: "",
  dueDate: "",
  assignee: "",
});

function toggleAddNewTask() {
  addingNewTask.value = !addingNewTask.value;

  if (addingNewTask.value) {
    setTimeout(() => {
      newTaskTitleField.value.focus();
    }, 100);
  } else {
    newTaskTitle.value = "";
  }
}

function updateNewTask() {
  if (newTaskTitle.value) {
    // Create a new task object for each addition
    const taskToAdd = {
      _id: Date.now(),
      title: newTaskTitle.value,
      status: "Not Started",
      dueDate: "", // Or any default value
      assignee: "", // Or any default value
    };

    const updatedTasks = [...props.tasks, taskToAdd];

    emitUpdateTasks(updatedTasks);

    // Reset the new task title for the next input
    newTaskTitle.value = "";
    addingNewTask.value = false;
  } else {
    toggleAddNewTask();
  }
}

function emitUpdateTasks(tasks) {
  emit("update:tasks", tasks);
}
</script>

<style lang="scss">
.task-item {
  .task-item-toolbar {
    opacity: 0;
  }

  &:hover {
    .task-item-toolbar {
      opacity: 1;
    }
  }
}
</style>
