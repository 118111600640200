<template>
  <div>
    <PageHeader
      title="Growth Plan"
      sub-title="Execute on your growth strategy with initiatives, tasks and experiments tied to your business goals."
    >
      <template #actions>
        <gm-button primary label="New initiative" icon="plus" @click="openNewInitiativeModal" />
        <Modal
          v-model="modalOpen"
          :dynamicComponent="NewInitiativeViewVue"
          size="large"
          noPadding
          @closed="handleModalClosed"
          :no-transition="modalTransitionDisabled"
          @emittedToModal="emittedToModal"
        />
      </template>
    </PageHeader>

    <gm-page-container>
      <RoadMap :startDate="{ month: 'april', year: 2023 }" :tasks="initiativesStore.initiatives" />
    </gm-page-container>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import PageHeader from "../components/PageHeader.vue";
import RoadMap from "../components/RoadMap.vue";
import Modal from "../components/Modal.vue";
import NewInitiativeViewVue from "./initiatives/NewInitiativeView.vue";
import useInitiativesStore from "@/store/initiatives";
import useUserStore from "@/store/user";
import { onMounted, onBeforeUnmount, ref, watch } from "vue";
import { useNotify } from "@/plugins/useNotify";
const $notify = useNotify();
const router = useRouter();

// Init stores
const initiativesStore = useInitiativesStore();
const userStore = useUserStore();

const tasks = ref([]);
const modalOpen = ref(false);
const modalTransitionDisabled = ref(false);

async function getInitiatives() {
  await initiativesStore.fetchInitiatives({ companyId: userStore.companyId });
}

// Watch companyId for changes
const stopWatch = watch(
  () => userStore.companyId,
  async (newCompanyId, oldCompanyId) => {
    if (newCompanyId) {
      // Only proceed if newCompanyId is truthy
      await getInitiatives();
    }
  },
  { immediate: true } // This ensures the watcher fires immediately upon being set up
);

function openNewInitiativeModal() {
  router.push({ query: { add: "action-plan" } });
  modalOpen.value = true;
}

async function handleModalClosed() {
  router.push({ query: null });
  // Re-fetch initiatives
  await getInitiatives();
}

async function emittedToModal(event) {
  console.log("emittedToModal", event);
  if (event === "closed") {
    modalOpen.value = false;
    await handleModalClosed();
  }
}

onMounted(async () => {
  // Check if there is a query param for adding a new initiative
  if (router.currentRoute.value.query.add === "action-plan") {
    modalTransitionDisabled.value = true;
    openNewInitiativeModal();
    setTimeout(() => {
      modalTransitionDisabled.value = false;
    }, 1000);
  }
});

onBeforeUnmount(() => {
  stopWatch();
});
</script>
@/store/initiatives
